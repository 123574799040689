import {createStore, combineReducers, applyMiddleware} from 'redux'
import {reducer as toastr} from 'react-redux-toastr';
import {UserInfoService, UserMenusService} from './services/AuthService';
import {UserTypesService, UserRolesService, AppSettingsService, TrmTypesService, CrmActionsService} from './services/CommonServices';

import {FilterMetadataService} from './services/CommonServices';


const AppMiddleware = store => next => action => {
    switch (action.type) {
        
            case 'GET_CRM_ACTION' :
                CrmActionsService().then(response => {
                if (response && response.data)
                    store.dispatch({type: 'set', crmAction: response.data});
            });
            break;
        case 'GET_TRM_TYPES' :
            TrmTypesService().then(response => {
                if (response && response.data)
                    store.dispatch({type: 'set', trmTypes: response.data});
            });
            break;
       

        case 'GET_HIRING_AGENTS' :
            UserRolesService().then(response => {
                if (response && response.data)
                    store.dispatch({type: 'set', hiringAgents: response.data});
            });
            break;
    }
    return next(action);
}
const initialState = {
    sidebarShow: 'responsive',
    userInfo: null,
    userMenus: null,
    carrierData: null,
    userData: null,
    userTypes: null,
    appSettings: [],
    userRoles: null,
    hiringAgents: null,
    vendorData: null,
    vendorCategories: null,
    countryMasters: null,
    clientCategories: null,
    jobCompanies: null,
    jobTaxForms: null,
    isSign: null,
    externalSelect: null,
    heavydutySelect: null,
    themeFontChoose: null,
    taskTypeData: null,
    formList: false,
    goBackUrl: '',
    mergeMetaDataVendor: [],
    mergeMetaDataCarrier: [],
    healthInsuranceSelect: null
}

const changeState = (state = initialState, {type, ...rest}) => {
    switch (type) {
        case 'set':
            return {...state, ...rest}
        default:
            return state
    }
}

const store = createStore(combineReducers({changeState, toastr}), applyMiddleware(AppMiddleware));
export default store
