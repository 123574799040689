import {POST, GET, UPLOAD} from './ApiContract';

export const LoginService = (payload, urlParams) => {
    return POST('users/gateway/login', payload, urlParams);
}

export const AdminLoginService = (payload, urlParams) => {
    return POST('users/gateway/trmadminlogin', payload, urlParams);
}

export const LogintrmService = (payload, urlParams) => {
    return POST('users/gateway/trmlogin', payload, urlParams);
}

export const UserInfoService = (payload) => {
    return GET('users/get', payload);
}

export const UserMenusService = (payload) => {
    return GET('users/menus', payload);
}

export const ChangePasswordService = (payload) => {
    return POST('users/changepassword', payload);
}

export const ForgotpasswordService = (payload) => {
    return POST('users/gateway/commonforgotpassword', payload);
}


export const CheckResetService = (payload) => {
    return GET('users/gateway/commonlogincheckresetlink', payload);
}

export const SetPasswordService = (payload) => {
    return POST('users/gateway/resetcommonlogin', payload);
}

export const LogoutService = (payload) => {
    return GET('users/csrflogout', payload);
}

export const UploadPhotoService = (payload) => {
    return UPLOAD('users/profilephoto', payload);
}